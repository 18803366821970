var slugify = require("slugify");
var sha256 = require('js-sha256').sha256;
var axios = require('axios');

const slug = (url) => {
    return slugify(url, { lower: true, strict: true });
}

const isEven = (n) => !(n & 1);

const adjustImgWidthAndHeight = (width, maxWidth, height, maxHeight) => {
    let adjHeight = Math.min(maxHeight, height);
    let adjWidth = (width * adjHeight) / height;
    if (maxWidth < adjWidth) {
        adjHeight = (maxWidth * adjHeight) / adjWidth;
        adjWidth = maxWidth;
    }
    if (maxHeight < adjHeight) {
        adjWidth = (maxHeight * adjWidth) / adjHeight;
        adjHeight = maxHeight;
    }
    return {
        width: adjWidth,
        height: adjHeight
    }
}

const pushToGTM = (event) => {
    setTimeout(() => {
        if (window?.dataLayer) {
            window.dataLayer.push(event);
        }
    }, 0);
}

const postFBEvent = async (eventName, formType) => {
    try {
        const { data: ip } = await axios.get("https://api.ipify.org/?format=json");
        const data = {
            "data": [
                {
                    "event_name": eventName,
                    "event_time": new Date().getTime() / 1000,
                    "event_source_url": window.location.href,
                    "action_source": "website",
                    "user_data": {
                        "client_ip_address": ip.ip,
                        "client_user_agent": navigator.userAgent,
                        "ct": sha256("helsinki"),
                        "st": sha256("uusimaa")
                    },
                    "custom_data": {
                        "form_type": formType,
                    },
                }
            ]
        }
        await axios.post(`https://graph.facebook.com/v10.0/157802099264372/events?access_token=EAAGmFT9jQU8BAInLPQ6ZBLq9VdA2s2MKTawtuzJM7R6bIxIY8NuYgSmHy2h5eJN9CHVZBIswvRYGJqb9SgTNCqvi8hedLPZATnQC1ZBkdHbxxxcMERRyMF1ih01CkaayDRZCgo47OPaUBxDEL98dJy1tX7q5JRsuyJCpMsrHmg6BZATyQo8FJwt4NG1wQfiQ0ZD`, data);

    } catch (e) {
        console.error(e)
    }
}

const setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    if (typeof window !== 'undefined') {
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }
}

const getCookie = (cName) => {
    const name = cName + "=";
    let cDecoded = '';
    if (typeof window !== 'undefined') {
        cDecoded = decodeURIComponent(document.cookie);
    }
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) {
            res = val.substring(name.length);
        }
    })
    return res;
}

const getVocabularyItemSlugsFromContentHTML = (contentHTML) => {
    const hrefUrls = [];
    const hrefMatches = contentHTML.matchAll(/<a[^>]*href=["']([^"']*)["']/gm);
    Array.from(hrefMatches).forEach((a) => {
        if (a.length > 1) hrefUrls.push(a[1])
    });

    const vocabularySlugs = [];
    const itemKey = 'glossary#';
    const vocabularyUrls = hrefUrls.filter((url) => url.includes(itemKey));
    vocabularyUrls.forEach((u) => {
        const parts = u.split(itemKey);
        if (parts.length > 0) vocabularySlugs.push(parts[parts.length - 1]);
    });

    return vocabularySlugs;
}

const getDefinedTermPopUpItem = (definedTerm) => {
    return {
        title: definedTerm.title,
        content: definedTerm.description.childMarkdownRemark.html,
        description: definedTerm.description.childMarkdownRemark.excerpt,
        slug: definedTerm.slug,
    }
}

const pushSAEvent = (eventName) => {
    if (typeof window !== "undefined") {
        if (window.sa_event != null) {
            window.sa_event(eventName)
        }
    }
}

const useLocalizeHref = (lang) => (href) => lang && lang !== 'en-US' ? `/${lang}${href}` : href;

const useTranslation = (lang) => {
    const isLanguageDe = () => lang === 'de';

    return (key) => {
        if (!isLanguageDe()) {
            return key;
        }

        if (key === 'Platform') {
            return 'Plattform';
        }
        if (key === 'Industries') {
            return 'Branchen';
        }
        if (key === 'Platform catalogue') {
            return 'Plattformkatalog';
        }
        if (key === 'Docs') {
            return 'Dokumentation';
        }
        if (key === 'Documentation') {
            return 'Dokumentation';
        }
        if (key === 'Pricing') {
            return 'Preisgestaltung';
        }
        if (key === 'Customers') {
            return 'Kunden';
        }
        if (key === 'Partners') {
            return 'Partner';
        }
        if (key === 'Partners portal') {
            return 'Partnerportal';
        }
        if (key === 'Careers') {
            return 'Karriere';
        }
        if (key === 'schedule a demo') {
            return 'vereinbaren Sie eine Demo';
        }
        if (key === 'Publications') {
            return 'Veröffentlichungen';
        }
        if (key === 'News & Blogs') {
            return 'News und Blogs';
        }
        if (key === 'Sign up for the newsletter!') {
            return 'Melden Sie sich für den Newsletter an!';
        }
        if (key === 'Thanks for submitting!') {
            return 'Danke für die Übermittlung!';
        }
        if (key === 'Frends on Instagram') {
            return 'Frends auf Instagram';
        }
        if (key === 'Frends on Twitter') {
            return 'Frends auf Twitter';
        }
        if (key === 'Frends on Facebook') {
            return 'Frends auf Facebook';
        }
        if (key === 'Frends on LinkedIn') {
            return 'Frends auf LinkedIn';
        }
        if (key === 'Frends on Github') {
            return 'Frends auf Github';
        }
        if (key === 'Frends on Vimeo') {
            return 'Frends auf Vimeo';
        }
        if (key === 'Read our customer reviews on') {
            return 'Lesen Sie unsere Kundenbewertungen auf';
        }
        if (key === 'and') {
            return 'und';
        }
        if (key === 'G2 The Fastest Implementation product in the Implementation Index had the shortest go-live time in its category.') {
            return 'G2 Die Lösung mit der schnellsten Implementierung im Implementation Index hatte die kürzeste Go-Live-Zeit in seiner Kategorie.';
        }
        if (key === 'G2 Products in the High Performer quadrant in the Europe Regional Grid® Report have high customer Satisfaction scores and low Market Presence scores compared to the rest of the category.') {
            return 'G2 Lösungen im ‘High Performer’-Quadranten des Europe Regional Grid® Report weisen im Vergleich zum Rest dieser Kategorie hohe Kundenzufriedenheits- und niedrige Marktpräsenzwerte auf.';
        }
        if (key === 'Whistleblowing') {
            return 'Whistleblowing';
        }
        if (key === 'frends akatemia') {
            return 'frends akademie';
        }
        if (key === 'Connectors and Tasks') {
            return 'Konnektoren & Tasks';
        }
        return key;
    }
};

const getShownItemsForLocale = (items, locale) => {
    return locale !== 'de' ? items : items.filter((i) => i.node.isDeTranslated);
};

const isDeviceInDeLocale = () => {
    return navigator?.language === 'de' || navigator?.language?.includes('de-');
};

module.exports = {
    slug: slug,
    isEven: isEven,
    adjustImgWidthAndHeight: adjustImgWidthAndHeight,
    pushToGTM: pushToGTM,
    setCookie: setCookie,
    getCookie: getCookie,
    getVocabularyItemSlugsFromContentHTML: getVocabularyItemSlugsFromContentHTML,
    getDefinedTermPopUpItem: getDefinedTermPopUpItem,
    pushSAEvent: pushSAEvent,
    postFBEvent: postFBEvent,
    useLocalizeHref: useLocalizeHref,
    useTranslation: useTranslation,
    getShownItemsForLocale: getShownItemsForLocale,
    isDeviceInDeLocale: isDeviceInDeLocale,
}