import React from "react";
import * as ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { setCookie } from "./src/helpers";

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location.pathname === '/industries/energy-and-utilities') {
        window.location.replace('https://campaign.frends.com/frends-for-energy')
    }

    if (location.pathname === '/landing/path-from-biztalk-to-frends-migration') {
        window.location.replace('https://campaign.frends.com/frends-for-biztalk')
    }

    if (location.pathname === '/de/industries/energie-und-versorgung') {
        window.location.replace('https://campaign.frends.com/de/frends-for-energy')
    }

    if (location.pathname === '/de/industries/einzelhandel') {
        window.location.replace('https://campaign.frends.com/de/frends-for-retail')
    }

    if (location && location.state) {
        location.state.referrer = prevLocation ? prevLocation.pathname : null
    }

    if (location.pathname.includes('/de')) {
        setCookie('hasVisitedDePages', true, 60);
    }
}

export const replaceHydrateFunction = () => {
    return (element, container, callback) => {
        ReactDOM.render(element, container, callback)
    }
}

export const wrapRootElement = ({ element }) => {
    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: 'https://frends.com',
                scope: 'trial:read trial:create tenant:read purchase:create openid profile email',
            }}
        >
            {element}
        </Auth0Provider>
    );
}